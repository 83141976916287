<template>
  <div>

    <plan-add-new :is-add-new-data-sidebar-active.sync="isAddNewDataSidebarActive" :form-data-edit="formDataEdit"
      :form-disabled="formDisabled" @fetch-data="fetchData" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="3" class="">
            <label>Mostrar</label>
            <v-select v-model="tableSettings.perPage" :options="perPageOptions" :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>registros</label>
          </b-col>
          <b-col cols="12" md="9">
            <div class="d-flex align-items-center justify-content-end">
              <b-col md="4" class="offset-8">
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Buscar" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Borrar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-button variant="primary" class="btn-icon rounded-circle mr-1" @click="isAddNewDataSidebarActive = true"
                v-if="permissions.includes('plans.store')">
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refRoleListTable" class="position-relative" :items="dataTable" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="tableSettings.sortBy" show-empty empty-text="No se encontraron datos"
        :sort-desc.sync="tableSettings.sortDesc" :filter="filter" :filter-included-fields="filterOn"
        @filtered="onFiltered">

        <template #cell(status)="data">
          <b-badge variant="success" v-if="(data.item.status == 1)">
            Activo
          </b-badge>
          <b-badge variant="danger" v-if="(data.item.status == 0)">
            Inactivo
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button :to="{ name: 'plans-view', params: { id: data.item.id } }" variant="success"
            class="btn-icon rounded-circle ml-2" v-b-tooltip.hover.v-success title="Tarifas"
            v-if="permissions.includes('plans.rates')">
            <feather-icon icon="DollarSignIcon" />
          </b-button>
          <b-button @click="country(data.item.id)" variant="primary" class="btn-icon rounded-circle ml-2"
            v-b-tooltip.hover.v-primary title="Destinos">
            <feather-icon icon="GlobeIcon" />
          </b-button>
          <b-button @click="cobertura(data.item.id)" variant="secondary" class="btn-icon rounded-circle ml-2"
            v-b-tooltip.hover.v-primary title="Coberturas">
            <feather-icon icon="BriefcaseIcon" />
          </b-button>
          <b-button @click="editData(data.item)" class="btn-icon rounded-circle ml-2" v-b-tooltip.hover.v-warning
            title="Editar" variant="warning" v-if="permissions.includes('plans.edit')">
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button @click="deleteData(data.item)" variant="danger" class="btn-icon rounded-circle ml-2"
            v-b-tooltip.hover.v-danger title="Eliminar" v-if="permissions.includes('plans.delete')">
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Viendo del {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }}
              registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="tableSettings.page" :total-rows="totalRows" :per-page="tableSettings.perPage"
              first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BFormGroup, BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import PlanAddNew from './PlanAddNew.vue'
import Ripple from 'vue-ripple-directive'
export default {
  name: 'Role',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    PlanAddNew,
    VBTooltip,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend
  },
  data() {
    return {
      filter: null,
      filterOn: [],
      formDisabled: false,
      isAddNewDataSidebarActive: false,
      refRoleListTable: null,
      perPageOptions: [25, 50, 100],
      searchQuery: '',
      inputs: [
        { value: 'id', title: 'Id' },
        { value: 'name', title: 'Plan' },
        { value: 'description', title: 'Descripción' },
      ],
      typeSearch: [
        { value: 'LIKE', title: 'Igual' },
        { value: 'NOT LIKE', title: 'No es igual' },
        { value: '>', title: 'Mayor que' },
        { value: '<', title: 'Menor que' }
      ],
      tableColumns: [
        { key: 'name', label: 'Plan', sortable: true },
        { key: 'description', label: 'Descripción', sortable: true },
        { key: 'status', label: 'Estado', sortable: true },
        { key: 'actions', label: 'acciones' },
      ],
      sortBy: 'id',
      isSortDirDesc: true,
      totalRows: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      dataTable: [],
      showLoadingTable: false,
      tableSettings: {
        filter: this.$route.params && this.$route.params.id,
        searchQuery: '',
        input: '',
        typeSearch: '',
        perPage: 25,
        page: 1,
        sortBy: 'id',
        sortDesc: true,
      },
      formDataEdit: {},
      userData: null,
      permissions: []
    }
  },
  watch: {
    "tableSettings.sortBy": {
      handler(val) {
        this.fetchData()
      },
    },
    "tableSettings.sortDesc": {
      handler(val) {
        this.fetchData()
      },
    },
    "tableSettings.perPage": {
      handler(val) {
        this.fetchData()
      },
    },
    "tableSettings.searchQuery": {
      handler(val) {
        this.fetchData()
      },
    },
    "tableSettings.page": {
      handler(val) {
        this.fetchData()
      },
    },
  },
  created() {
    this.fetchData()
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.permissions = this.userData.permissions
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    cobertura(id) {
      this.$router.push('/coberturas/' + id);
    },
    country(id) {
      this.$router.push('/countries/' + id);
    },
    fetchData() {
      this.showLoadingTable = true
      this.$http.get('/plans/list', { params: this.tableSettings }).then((response) => {
        this.dataTable = response.data.plans
        this.totalRows = response.data.total
        this.dataMetaCounter()
        this.showLoadingTable = false
      })
    },
    dataMetaCounter() {
      const localItemsCount = this.dataTable.length
      this.dataMeta.from = this.tableSettings.perPage * (this.tableSettings.page - 1) + (localItemsCount ? 1 : 0)
      this.dataMeta.to = this.tableSettings.perPage * (this.tableSettings.page - 1) + localItemsCount
      this.dataMeta.of = this.totalRows
    },
    detailData(row) {
      this.formDisabled = true
      this.formDataEdit = row
      this.isAddNewDataSidebarActive = true
    },
    editData(row) {
      this.formDataEdit = row
      this.isAddNewDataSidebarActive = true
    },
    deleteData(row) {
      this.$swal({
        title: `Esta séguro de eliminar el plan ${row.name}?`,
        text: "Este proceso no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post('/plans/delete/' + row.id).then(() => {
            this.fetchData()
            this.$swal({
              icon: 'success',
              title: 'Plan eliminado!',
              text: 'El plan ha sido eliminado.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Eliminación cancelada',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
