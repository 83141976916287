<template>
  <b-sidebar
    id="add-new-role-sidebar"
    :visible="isAddNewDataSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-data-sidebar-active', val)"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 v-if="newForm" class="mb-0">
          Crear nuevo plan
        </h5>
        <h5 v-else class="mb-0">
          <span v-if="formDisabled">Detalle de plan</span>
          <span v-else>Editar plan</span>
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="close"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        ref="formData"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="newForm ? store() : edit()"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="{ errors }"
            name="Plan"
            rules="required"
          >
            <b-form-group
              label="Plan"
              label-for="name"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="name"
                v-model="formData.name"
                :state="errors.length > 0 ? false:null"
                autofocus
                trim
                :disabled="formDisabled"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="{ errors }"
            name="Descripción"
            rules="required"
          >
            <b-form-group
              label="Descripción"
              label-for="name"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-textarea
                id="description"
                v-model="formData.description"
                :state="errors.length > 0 ? false:null"
                autofocus
                trim
                :disabled="formDisabled"
                placeholder="Descripción del plan"
                rows="8"
              />
              
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

           <!-- Status -->
           <validation-provider
            #default="{ errors }"
            name="Estado"
            rules="required"
          >
            <b-form-group
              label="Estado"
              label-for="status"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <v-select 
                id="status"
                v-model="formData.status"
                :options="status"
                :reduce="val => val.value"
                :state="errors.length > 0 ? false:null"
                autofocus
                trim
                :disabled="formDisabled"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

        </b-form>
      </validation-observer>
    </template>
    <template v-if="!formDisabled" #footer>
      <b-button-toolbar justify>
        
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            style="width: 50%"
            @click="newForm ? store() : edit()"
          >
            Guardar
          </b-button>
          
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            style="width: 50%"
            @click="close"
          >
            Cancelar
          </b-button>
      </b-button-toolbar>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox, BFormCheckboxGroup, BButtonToolbar, BFormTextarea
} from 'bootstrap-vue'
import es from 'vee-validate/dist/locale/es';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  name: 'RoleAddNew',
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButtonToolbar,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isAddNewDataSidebarActive: {
      type: Boolean,
      required: true,
    },
    formDisabled: {
      type: Boolean,
      required: true,
    },
    formDataEdit: {
      type: Object,
      required: false,
    },
  },
  watch: {
    formDataEdit: {
      handler(item, oldItem) {
        const id = parseInt(item.id) ?? null
        if (id) {
          this.newForm = false
          this.formData = {
            id: item.id,
            name: item.name,
            description: item.description,
            status: item.status,
          }
        }
      },
    },
  },
  data() {
    return {
      newForm: true,
      formData: {
        id: null,
        name: '',
        description: '',
        status: null,
      },
      status: [
        {label: 'Activo', value: 1},
        {label: 'Inactivo', value: 0},
      ],
    }
  },
  methods: {
    store() {
      localize('es', es);
      this.$refs.formData.validate().then(success => {
        if(success) {
          this.$http.post('/plans/store', this.formData)
          .then((response) => {
            this.$emit('fetch-data')
            this.$emit('update:is-add-new-data-sidebar-active', false)
            this.resetForm()
            this.$refs.formData.reset()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Plan creado`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Plan ${response.data.plan.name} creado con éxito`,
              },
            })
          })
          .catch(error => {
            console.log('error')
          })
        }
      })
    },
    edit() {
      localize('es', es);
      this.$refs.formData.validate().then(success => {
        if(success) {
          this.$http.post('/plans/edit', this.formData)
          .then((response) => {
            this.$emit('fetch-data')
            this.$emit('update:is-add-new-data-sidebar-active', false)
            this.resetForm()
            this.$refs.formData.reset()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Plan editado`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Plan ${response.data.plan.name} editado con éxito`,
              },
            })
          })
          .catch(error => {
            console.log('error')
          })
        }
      })
    },
    resetForm() {
      this.formData= {
        id: null,
        name: '',
      }
    },
    close() {
      this.$emit('update:is-add-new-data-sidebar-active', false)
      // this.resetForm()
      // this.$refs.formData.reset()
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
